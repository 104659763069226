<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Renegociação</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">                   
                  <span>Renegociação</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <section class="container">
          <!-- Conteúdo principal -->
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <button
                  v-if="!exibeFiltro"
                  class="btn btn-sm btn-primary"
                  @click="exibeFiltro = true"
                >
                  Exibir filtro
                </button>
                <button
                  v-else
                  class="btn btn-sm btn-primary"
                  @click="exibeFiltro = false"
                >
                  Ocultar filtro
                </button>
              </div>
            </div>
            <div
              v-if="exibeFiltro"
              class="row"
            >
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Plataforma:</label>
                <input
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Aluno:</label>
                <input
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Responsavel:</label>
                <input
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Tipo Pagamento:</label>
                <select class="form-control">
                  <option value="">
                    Selecione o tipo
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Origem:</label>
                <select class="form-control">
                  <option value="">
                    -Selecione a Origem-
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Status:</label>
                <select class="form-control">
                  <option value="">
                    Selecione o Status
                  </option>
                </select>
              </div>
              <div
                div
                class="col-sm-12 col-md-12 col-lg-4 text-center mt-4"
              >
                <label>Emissão:</label>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>De:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>Até:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div
                div
                class="col-sm-12 col-md-12 col-lg-4 text-center mt-4"
              >
                <label>Vencimento:</label>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>De:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>Até:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div
                div
                class="col-sm-12 col-md-12 col-lg-4 text-center mt-4"
              >
                <label>Data Pagamento:</label>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>De:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>Até:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <br>
                <button class="btn btn-sm btn-primary">
                  <small>Limpa Filtros</small>>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          Total Cobrado:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          Total Pago:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          A vencer:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          Vencido:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 table-responsive mt-2">
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr class="text-center">
                      <th>
                        <small class="font-weight-bold">Plataforma</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Aluno</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Valor</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Emissão</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Vencimento</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Pagamento</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Status</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Link</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Renegociar</small>
                      </th>
                    </tr>
                  </thead>
                  <!-- <tbody v-if="fastOcorrenciaLoading">
                    <tr>
                      <td colspan="5" class="text-center">
                        Carregando Ocorrências...
                      </td>
                    </tr>
                  </tbody> -->
                  <tbody>
                    <tr />
                  </tbody>
                  <!-- <tbody v-else>
                    <tr>
                      <td colspan="5" class="text-center">
                        Nenhuma Ocorrência Cadastrado
                      </td>
                    </tr>
                  </tbody> -->
                </table>
              </div>
              <!-- <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="10"
                  :items="fastOcorrenciaFiltro"
                  @changePage="fastOcorrenciaPagination = $event"
                />
              </div> -->
            </div>
          </div>
          <!-- /Conteúdo principal -->
        </section>
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoTesourariaRenegociacao",
  components: {
    
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      exibeFiltro: false
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    
  },
};
</script>

<style></style>
